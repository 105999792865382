import api from '../api/v1'
import router from '../router'

const initialGlobalDate = JSON.parse(localStorage.getItem('EL-Date')) || {
  date: new Date().toISOString().substring(0, 10),
  setAt: new Date()
}

export default {
  namespaced: true,
  state: {
    isLoadedApp: false,
    isLoading: false,
    department: null,
    filters: {},
    mapMode: 'coursesMap',
    mapType: 'terrain',
    fullViewMode: false,
    globalDate: initialGlobalDate
  },
  mutations: {
    SET_APP_LOADED_STATE (state, loaded = true) {
      state.isLoadedApp = loaded
    },
    LOADING (state, loading) {
      state.isLoading = loading
    },
    SET_MAP_MODE (state, mode) {
      state.mapMode = mode
    },
    SET_MAP_TYPE (state, type) {
      state.mapType = type
    },
    SET_GLOBAL_DATE (state, date) {
      state.globalDate = date
      localStorage.setItem('EL-Date', JSON.stringify(date))
    },
    SET_DEPARTMENT (state, department) {
      department.location = {
        lat: parseFloat(department.lat),
        lng: parseFloat(department.lng)
      }
      state.department = department
    },
    SET_FILTERS (state, filters) {
      const filterSettlementTypes = clientType => filters.clientSettlementTypes.filter(type => type[clientType]).map(type => type.type)

      state.filters = {
        ...filters,
        ordersCourseTypes: filters.courseTypes.filter((type) => type !== 'Wymiana'),
        individualSettlementTypes: filterSettlementTypes('individual'),
        companySettlementTypes: filterSettlementTypes('company'),
        allBdoTypes: filters.clientBdoTypes.map(type => type.type),
        driverStatuses: [
          { name: 'W trakcie trasy', value: false },
          { name: 'Dostępni', value: true }
        ],
        codDepositTypes: ['W biurze', 'W trasie']
      }
    },
    SET_FULL_VIEW_MODE (state) {
      state.fullViewMode = true
    }
  },
  getters: {
    getGlobalDate (state) {
      const { date, setAt } = state.globalDate
      const todayDate = new Date().setHours(0, 0, 0, 0)
      const setAtDate = new Date(setAt).setHours(0, 0, 0, 0)
      // if date was set today return it, otherwise return today date
      return todayDate === setAtDate ? date : new Date().toISOString().substring(0, 10)
    }
  },
  actions: {
    initApp ({ dispatch }) {
      return new Promise((resolve) => {
        dispatch('departments/getItems', { params: {} }, { root: true })
          .then(() => {
            dispatch('fetchSavedDepartment').then(() => {
              dispatch('fetchDepartmentResources')
              resolve()
            })
          })
      })
    },
    fetchDepartmentResources ({ commit, dispatch, state }) {
      commit('LOADING', true)
      const containerTypesParams = router.currentRoute.name === 'containersMap'
        ? { params: { departmentId: state.department.id, onRoute: true } } : null

      const filters = dispatch('getFilters')
      const settings = dispatch('tables/getTablesSettings', null, { root: true })
      const businessPlaces = dispatch('businessPlaces/getItems', null, { root: true })
      const communes = dispatch('communes/getItems', null, { root: true })
      const drivers = dispatch('drivers/getItems', { params: { filters: { departmentId: state.department.id } } }, { root: true })
      const containers = dispatch('containers/getItems', null, { root: true })
      const containerTypes = dispatch('containerTypes/getItems', containerTypesParams, { root: true })
      const debrisTypes = dispatch('debrisTypes/getItems', { params: { aggregate: false } }, { root: true })
      const vehicles = dispatch('vehicles/getItems', null, { root: true })
      const terminals = dispatch('terminals/getItems', null, { root: true })
      const users = dispatch('users/getItems', null, { root: true })
      const middlemen = dispatch('middlemen/getItems', { params: { endpoint: 'clients', filters: { isMiddleman: true, withoutPatron: true } } }, { root: true })
      const trailers = dispatch('trailers/getItems', null, { root: true })

      return Promise.all([businessPlaces, communes, containers, containerTypes, drivers, debrisTypes, filters, vehicles, settings, terminals, trailers, users, middlemen])
        .finally(() => {
          commit('SET_APP_LOADED_STATE')
          commit('LOADING', false)
        })
    },
    setLoading ({ commit }, loading) {
      commit('LOADING', loading)
    },
    setMapMode ({ commit }, mode) {
      commit('SET_MAP_MODE', mode)
    },
    setMapType ({ commit }, type) {
      commit('SET_MAP_TYPE', type)
    },
    setGlobalDate ({ commit }, date) {
      const payload = {
        date,
        setAt: new Date()
      }
      commit('SET_GLOBAL_DATE', payload)
    },
    fetchSavedDepartment ({ state, rootState, commit }) {
      return api.getUserSettings('department')
        .then((resp) => {
          const department = state.department || rootState.departments.items[0]
          commit('SET_DEPARTMENT', resp.data[0] ? JSON.parse(resp.data[0].value) : department)
        })
    },
    setDepartment ({ commit, dispatch }, department) {
      commit('SET_DEPARTMENT', department)
      const value = JSON.stringify(department)
      return api.updateUserSettings({ viewType: 'department', value }).then(() => {
        dispatch('fetchDepartmentResources')
      })
    },
    setClientsOptions ({ state, commit }, params) {
      if (!params) params = { departmentId: state.department.id }
      params = {
        filters: { withoutPatron: true },
        ...params
      }
      api.getSimplifiedList('clients', params)
        .then((clients) => {
          const appFilters = {
            ...state.filters,
            clients: clients.data
          }
          commit('SET_FILTERS', appFilters)
        })
    },
    setBdoCardsOptions ({ state, commit, rootGetters }) {
      const { params } = rootGetters['tables/getTableParameters']('bdoCards')
      params.filterNames = ['sender_business_place', 'receiver_business_place', 'commune']
      api.getBdoCardsFilters(params)
        .then((resp) => {
          const appFilters = {
            ...state.filters,
            ...resp.data
          }
          commit('SET_FILTERS', appFilters)
        })
    },
    async setBdoCardTemplatesOptions({ state, commit }) {
      const [carrierBusinessPlacesResponse, receiverBusinessPlacesResponse, debrisTypesResponse] = await Promise.all([
        api.getBussinessPlaces({ withCompanyId: true, externalType: 'carrier' }),
        api.getBussinessPlaces({ withEupId: true, withCompanyId: true, externalType: 'receiver' }),
        api.getDebrisTypes({ withExternalBdoId: true, aggregate: false })
      ])

      const carrierBusinessPlaces = carrierBusinessPlacesResponse.data
      const receiverBusinessPlaces = receiverBusinessPlacesResponse.data
      const bdoCardTemplateDebrisTypes = debrisTypesResponse.data.debrisTypes

      const appFilters = {
        ...state.filters,
        carrierBusinessPlaces,
        receiverBusinessPlaces,
        bdoCardTemplateDebrisTypes
      }

      commit('SET_FILTERS', appFilters)
    },
    getFilters ({ commit, dispatch }) {
      return api.getFilters()
        .then((filters) => {
          commit('SET_FILTERS', filters.data)
          dispatch('setClientsOptions')
        })
    },
    setFullViewMode ({ commit }) {
      commit('SET_FULL_VIEW_MODE')
    },
    setAppLoadedState ({ commit }) {
      commit('SET_APP_LOADED_STATE')
    }
  }
}
