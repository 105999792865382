import { noApiManipulation, ignoreDateRange, getTableOptions, mergeTableParameters, isFilterActive, parseTableParameters, mapTableFilters } from '../utils'
import api from '../api/v1'
import { Pagination } from '../models'
import { logisticsTables, clientTables, sharedTables } from '../const/tablesState'
import isLogisticsApp from '../const/isLogisticsApp'
import { decamelize } from 'humps'
import findIndex from 'lodash/findIndex'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'

const initialState = {
  savedTableSettings: {},
  ...(isLogisticsApp ? logisticsTables : clientTables),
  ...sharedTables
}

export default {
  namespaced: true,
  state: cloneDeep(initialState || {}),
  mutations: {
    SET_TABLE_CONFIG (state, tableName) {
      if (!state[tableName].parameters.length) {
        const table = getTableOptions(tableName)
        const savedTable = state.savedTableSettings[tableName]
        // parameters, filters and sorting are stored on BE side and are fetch on app init, and updated on each param change
        // parameters - array of shown columns in particular order that are parsed to full object in parseTableParameters
        // filters - array of objects { name, filterBy } filterBy is set for each filter in mapTableFilters
        // sorting is full config object doesn't need any parsing
        // table - is full config of the table
        // savedTable - is saved config in BE of the table
        state[tableName].parameters = savedTable?.hiddenParameters ? parseTableParameters(table, savedTable) : table.parameters || []
        state[tableName].filters = savedTable?.filters ? mapTableFilters(table.filters, savedTable.filters) : table.filters || []
        state[tableName].sorting = savedTable?.sorting || table.sorting
        state[tableName].specialFlag = table.specialFlag || ''
      }
    },
    APPEND_TABLE_CONFIG (state, params) {
      const { tableName, parameters } = params
      state[tableName].parameters = parameters
        ? mergeTableParameters(state[tableName].parameters, parameters)
        : state[tableName].parameters
    },
    SET_TABLE_SEARCH (state, { search, tableName }) {
      state[tableName].search = search
    },
    SET_TABLE_SORTING (state, { sorting, tableName }) {
      state[tableName].sorting = sorting
    },
    SET_TABLE_FILTERS_STATUS (state, { status, tableName }) {
      state[tableName].filtersEnabled = status
    },
    SET_TABLE_FILTERS (state, { filters, tableName }) {
      filters.forEach((filter) => {
        const index = findIndex(state[tableName].filters, ['name', filter.name])
        const hasFilterByKey = Object.keys(filter).includes('filterBy')
        state[tableName].filters[index].filterBy = hasFilterByKey ? filter.filterBy : state[tableName].filters[index].filterBy
        state[tableName].filters[index].disableSave = filter.disableSave
        function checkFilterParam (filterName) {
          if (Object.keys(filter).includes(filterName)) {
            state[tableName].filters[index][filterName] = filter[filterName]
          }
        }
        checkFilterParam('hide')
        checkFilterParam('disabled')
      })
    },
    SET_TABLE_PERSONALIZATION (state, { parameters, tableName }) {
      const newParameters = []
      parameters.forEach((item) => {
        const index = findIndex(state[tableName].parameters, ['name', item.name])
        const parameter = state[tableName].parameters[index]
        parameter.show = item.show
        newParameters.push(parameter)
      })
      state[tableName].parameters = newParameters.sort((a, b) => {
        if (a.name === 'actions') return 1
        if (b.name === 'actions') return -1
        return 0
      }) // actions should be always last pparameter
    },
    SET_TABLE_PAGINATION (state, { pagination, tableName }) {
      if (state[tableName]?.pagination) {
        state[tableName].pagination = pagination ? { ...state[tableName].pagination, ...pagination } : {}
      }
    },
    SET_TABLE_SETTINGS (state, { viewType, settings }) {
      if (viewType) {
        state.savedTableSettings[viewType] = settings
      } else {
        state.savedTableSettings = settings
      }
    },
    SET_INITIAL_STATE (state) {
      Object.assign(state, cloneDeep(initialState))
    }
  },
  actions: {
    setTableConfig ({ commit, dispatch, getters }, { tableName, disableFetch }) {
      dispatch('clearTablePagination', tableName)
      commit('SET_TABLE_CONFIG', tableName)
      // It's still strange we are dispatching fetch action here, especially when method is called "setTableConfig"...
      // I wonder if we actuallu need to do all those actions in table store instead of dispatching actions directly from the table component
      // or keep everything in model modules like courses, orders etc.
      // do we even need vuex for those actions?
      if (!disableFetch) {
        const params = getters.getTableParameters(tableName)
        return new Promise((resolve) => {
          dispatch(`${tableName}/getItems`, params, { root: true })
            .then(() => { resolve() })
        })
      }
    },
    callForNewItems ({ dispatch, getters }, tableName) {
      const params = getters.getTableParameters(tableName)
      return dispatch(`${tableName}/getItems`, params, { root: true })
    },
    appendTableConfig ({ commit }, parameters) {
      commit('APPEND_TABLE_CONFIG', parameters)
    },
    setTableSearch ({ commit, dispatch }, { search, tableName }) {
      dispatch('clearTablePagination', tableName)
      commit('SET_TABLE_SEARCH', { search, tableName })
      if (!noApiManipulation(tableName)) dispatch('callForNewItems', tableName)
    },
    setTableSorting ({ state, commit, dispatch }, { sorting, tableName }) {
      dispatch('clearTablePagination', tableName)
      commit('SET_TABLE_SORTING', { sorting, tableName })
      commit('SET_TABLE_SETTINGS', {
        viewType: tableName,
        settings: {
          ...state.savedTableSettings[tableName],
          sorting
        }
      })
      dispatch('updateTableSettings', { tableName })
      if (!noApiManipulation(tableName)) dispatch('callForNewItems', tableName)
    },
    setTablePagination ({ commit, dispatch }, { pagination, tableName, disableFetch }) {
      commit('SET_TABLE_PAGINATION', { pagination, tableName })
      if (!disableFetch) dispatch('callForNewItems', tableName)
    },
    clearTableFilters ({ state, dispatch }, { tableName, disableFetch, noApi }) {
      const filters = state[tableName].filters
        .filter(filter => !filter.hide)
        .map(filter => {
          let filterBy = ''
          if (filter.defaultValue) {
            filterBy = filter.defaultValue
          } else if (Array.isArray(filter.filterBy)) {
            filterBy = []
          }
          return { ...filter, filterBy }
        })

      dispatch('setTableFilters', { filters, tableName, disableFetch })
      dispatch('setTableFiltersStatus', { status: true, tableName, noApi })
    },
    setTableFilters ({ state, commit, dispatch, rootState }, { filters, tableName, disableFetch, disableSavingTableSettings }) {
      if (rootState.invoices?.isMultiselectActive) {
        commit('invoices/UNSELECT_ALL_ITEMS', tableName, { root: true })
      }
      if (rootState.courses?.isMultiselectActive) {
        commit('courses/UNSELECT_ALL_ITEMS', tableName, { root: true })
      }
      if (rootState.coursesWithoutCards?.isMultiselectActive) {
        commit('coursesWithoutCards/UNSELECT_ALL_ITEMS', tableName, { root: true })
      }
      dispatch('clearTablePagination', tableName)
      commit('SET_TABLE_FILTERS', { filters, tableName })
      const settings = state.savedTableSettings[tableName]
      // disableSave is used for filters that should not be saved in user settings
      // e.g. in 'Tasks.vue' we use it to disable saving filters for tabs (other than main tab "mineTasks") that automatically change 'taskTypes' filter
      // Settings for this filters should be saved only for main tab "mineTasks"
      const parseFilter = (filter) => {
        const previouslySavedFilter = settings && settings.filters ? settings.filters.find(savedFilter => savedFilter.name === filter.name) : {}
        const filterBy = filter.disableSave ? previouslySavedFilter.filterBy || '' : filter.filterBy
        return { name: filter.name, filterBy }
      }
      commit('SET_TABLE_SETTINGS', {
        viewType: tableName,
        settings: {
          ...settings,
          filters: state[tableName].filters.map(filter => parseFilter(filter))
        }
      })
      const tableOptions = getTableOptions(tableName)
      if (!tableOptions.disableSavingTableSettings) dispatch('updateTableSettings', { tableName })
      if (!disableFetch) dispatch('callForNewItems', tableName)
    },
    setTableFiltersStatus ({ commit, dispatch }, { status, tableName, noApi }) {
      dispatch('clearTablePagination', tableName)
      commit('SET_TABLE_FILTERS_STATUS', { status, tableName })
      if (!noApi) {
        return new Promise((resolve) => {
          dispatch(`${tableName}/getItems`, null, { root: true })
            .then(() => { resolve() })
        })
      }
    },
    setTablePersonalization ({ state, commit, dispatch }, { parameters, tableName }) {
      commit('SET_TABLE_PERSONALIZATION', { parameters, tableName })
      commit('SET_TABLE_SETTINGS', {
        viewType: tableName,
        settings: {
          ...state.savedTableSettings[tableName],
          hiddenParameters: state[tableName].parameters.filter(filter => !filter.show).map(filter => filter.name)
        }
      })
      dispatch('updateTableSettings', { tableName, timeout: 0 })
    },
    clearTablePagination ({ commit, getters }, tableName) {
      const { itemsPerPage } = getters.getTableConfig(tableName)?.pagination || {}
      commit('SET_TABLE_PAGINATION', { tableName, pagination: new Pagination({}, itemsPerPage) })
    },
    getTablesSettings({ commit }, tableName) {
      return api.getUserSettings(tableName)
        .then((resp) => {
          let settings = {}
          resp.data.forEach((setting) => {
            if (tableName) {
              settings = JSON.parse(setting.value)
            } else {
              settings[setting.viewType] = JSON.parse(setting.value)
            }
          })
          commit('SET_TABLE_SETTINGS', { viewType: tableName, settings })
        })
    },
    updateTableSettings ({ state }, { tableName, timeout = 1000 } = {}) {
      debounce(() => {
        const payload = { ...state.savedTableSettings[tableName], parameters: state[tableName].parameters.map(param => ({ ...param, show: !param.show })) }
        const value = JSON.stringify(payload)
        return api.updateUserSettings({ viewType: tableName, value })
      }
      , timeout)()
    }
  },
  getters: {
    getTableConfig: (state) => (tableName) => state[tableName],
    getFilterValue: (state) => (tableName, filterName) => {
      const filterBy = state[tableName].filters.find(filter => filter.name === filterName)?.filterBy
      return filterBy?.value || filterBy
    },
    getSavedFilterBy: (state) => (tableName, filterName) => {
      const filterBy = state.savedTableSettings[tableName]?.filters.find(filter => filter.name === filterName)?.filterBy
      return filterBy
    },
    isAnyFilterSelected: (state) => (tableName) => {
      return state[tableName]?.filters
        ?.filter(filter => !filter.hide)
        ?.some(filter => isFilterActive(filter.filterBy))
    },
    getTableParameters: (state, getters, rootState) => (tableName) => {
      const config = getters.getTableConfig(tableName)

      if (config) {
        const { specialFlag, pagination, search, filters, sorting } = config
        const activeFilters = {}

        if (search) activeFilters.query = search

        if (filters) {
          const setFilters = (filter) => {
            const { filterBy, name, default: defaultValue } = filter
            if (isFilterActive(filterBy) && !filter?.disabled) {
              const filterValue = filterBy.value ?? filterBy
              if (Array.isArray(filterBy)) {
                activeFilters[name] = filterValue.map(filter => filter.value ?? filter)
              } else {
                activeFilters[name] = filterValue
              }
            } else if (defaultValue) {
              activeFilters[name] = defaultValue
            }
          }

          if (config.filtersEnabled) {
            filters.forEach((filter) => {
              const { filterBy, name, options } = filter

              setFilters(filter)

              if (Array.isArray(filterBy) &&
              filterBy.length === 0 &&
              typeof options?.[0]?.value === 'boolean'
              ) activeFilters[name] = [null]
            })
          } else {
            filters.forEach(filter => {
              if (filter.hide) {
                setFilters(filter)
              } else if (filter.required) { // required filter requires default value
                activeFilters[filter.name] = filter.default
              }
            })
          }
        }

        const specialFlagKeys = typeof specialFlag === 'object' ? Object.keys(specialFlag) : null
        if (specialFlagKeys?.length) {
          specialFlagKeys.map((key) => {
            if (filters?.length) {
              filters.map((item) => {
                if (key === item.name) specialFlag[key] = item.filterBy
              })
            }
          })
        }

        let order
        if (sorting) {
          const field = config.parameters.find((item) => item.value === sorting.sortBy)
          order = {
            field: field ? decamelize(field.name) : 'ref_number',
            direction: sorting.sortDesc ? 'desc' : 'asc',
            date: field ? field.isDateField : false,
            ...sorting
          }
        }

        // daterange is all lower case because of BE nomenclature
        const daterange = ignoreDateRange(tableName) ? undefined
          : rootState[tableName].dateRange

        const departmentFilter = filters.find(filter => filter.name === 'departmentId')

        return {
          tableName,
          params: {
            departmentId: departmentFilter ? departmentFilter.filterBy?.value : rootState.core.department.id,
            ...specialFlag,
            filters: {
              ...activeFilters,
              daterange
            },
            order,
            ...pagination
          }
        }
      }
      return {}
    },
    getTableSearchQuery: (state) => (tableName) => state[tableName].search,
  }
}
