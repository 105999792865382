export default {
  header: [],
  table: {
    specialFlag: {
      endpoint: 'reports/main'
    },
    maximumDateRange: 5000,
    sorting: {
      sortBy: 'id',
      sortDesc: false
    },
    filters: [
      { name: 'clientIds', text: 'Klient', options: 'clients', filterBy: '', multiple: true },
      { name: 'driverIds', text: 'Kierowca', options: 'drivers', filterBy: '', multiple: true },
      { name: 'containerTypeIds', text: 'Zamówiony typ kontenera', options: 'containerTypes', filterBy: '', multiple: true },
      { name: 'patronIds', text: 'Opiekun', options: 'patrons', filterBy: '', multiple: true },
      { name: 'departmentIds', text: 'Oddział', options: 'departments', filterBy: [], multiple: true }
    ],
    parameters: [{}], // table to be configured requires length of this array
    actions: [],
    menu: []
  },
  details: {
    title: '',
    actions: [],
    sections: []
  }
}
